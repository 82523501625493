const { default: storage } = require('./storage/storage');
import config from './configs/config';
import Fetcher, { FetchError } from './helpers/fetcher';
import {
  Child,
  CustomerChargesReturnStripe,
  DataEntry,
  DataField,
  EnrolmentRequirement,
  Fillable,
  FullProgram,
  Message,
  MessageBody,
  MessageConversation,
  MessageConversationBody,
  PreenrolBody,
  Preenrolment,
  Program,
  ProgramSite,
  ProgramType,
  Purchase,
  RegisterBody,
  Tenant,
  User
} from './types';

export function apiResult<T>(result: T | FetchError): T {
  if (result instanceof Error) {
    throw result;
  }
  return result;
}

export default new (class Api {
  private fetcher = new Fetcher({
    baseUrl: config.backendUrl,
    getAccessToken: () => storage.getItem('token')
  });

  // User end points
  public getMe() {
    return this.fetcher.fetch<{ me: User }>('/me');
  }

  public getUser(userId: number) {
    return this.fetcher.fetch<{ user: User }>(`/users/${userId}`);
  }

  public updateUser(
    userId: number,
    body: {
      firstName?: string;
      lastName?: string;
      pickupPasscode?: string;
      details: { fieldId: number; value: object }[];
    }
  ) {
    return this.fetcher.fetch(`/users/${userId}`, {
      body,
      method: 'PATCH'
    });
  }
  // tenant end points
  public getUserTenant(id: number) {
    return this.fetcher.fetch<{ tenants: Tenant[] }>(`/users/${id}/tenants`);
  }

  public getTenant(tenantId: number) {
    return this.fetcher.fetch<{ tenant: Tenant }>(`/tenants/${tenantId}`);
  }

  public getAllPublicTenants() {
    return this.fetcher.fetch<{ tenants: Tenant[] }>('/public-tenants');
  }

  public getTenantSlug(tenantId: number) {
    return this.fetcher.fetch<{ slug: string }>(`/tenants/${tenantId}/slug`);
  }

  public postRegister(body: RegisterBody) {
    return this.fetcher.fetch('/register', {
      method: 'POST',
      body
    });
  }

  public postAttachUserToTenant(tenantId: number, userId: number) {
    return this.fetcher.fetch<{ user: User }>(
      `/tenants/${tenantId}/users/${userId}`,
      {
        method: 'POST'
      }
    );
  }
  // Children end points

  public getAdmins(tenantId: number) {
    return this.fetcher.fetch<{ admins: User[] }>(
      `/tenants/${tenantId}/administrators`
    );
  }

  // Children end points
  public getChildren(parentId: number) {
    return this.fetcher.fetch<{ children: Child[] }>(
      `/users/${parentId}/children`
    );
  }

  public getChild(childId: number) {
    return this.fetcher.fetch<{ child: Child }>(`/children/${childId}`);
  }

  public createChildWithDetails(
    parentId: number,
    body: {
      firstName: string;
      lastName: string;
      details: { fieldId: number; value: object }[];
    }
  ) {
    return this.fetcher.fetch<{ child: Child }>(
      `/users/${parentId}/children/with-details`,
      {
        method: 'POST',
        body
      }
    );
  }

  public updateChild(
    childId: number,
    body: {
      firstName?: string;
      lastName?: string;
      details: { fieldId: number; value: object }[];
    }
  ) {
    return this.fetcher.fetch(`/children/${childId}`, {
      body,
      method: 'PATCH'
    });
  }

  // Program end points
  public getPrograms(tenantId: number) {
    return this.fetcher.fetch<{ programs: Program[] }>(
      `/tenants/${tenantId}/programs`
    );
  }

  public getLiveProgramsWithMeta(tenantId: number, userId: number) {
    return this.fetcher.fetch<{ liveProgramsWithMeta: FullProgram[] }>(
      `/tenants/${tenantId}/users/${userId}/live-programs-with-meta`
    );
  }

  public getProgram(programId: number) {
    return this.fetcher.fetch<{ program: Program }>(`/programs/${programId}`);
  }

  public getNumberOfRegisteredChildren(programId: number) {
    return this.fetcher.fetch<{ numberOfRegisteredChildren: number }>(
      `/programs/${programId}/number-of-registered-children`
    );
  }
  public preenrol(programId: number, body: PreenrolBody) {
    return this.fetcher.fetch<{
      preenrolment: Preenrolment;
      enrolmentRequirements: EnrolmentRequirement[];
    }>(`/programs/${programId}/preenrol`, {
      body,
      method: 'POST'
    });
  }

  // stripe end points
  public stripeBottomSheet(enrolmentRequirementId: number) {
    return this.fetcher.fetch<{
      paymentIntent: string;
      ephemeralKey: string;
      customer: string;
      publishableKey: string;
    }>(
      `/enrolment-requirements/${enrolmentRequirementId}/pay-with-payment-sheet`,
      {
        method: 'POST',
        body: {}
      }
    );
  }

  public stripeCheckout(
    enrolmentRequirementId: number,
    successHref: string,
    cancelHref: string
  ) {
    return this.fetcher.fetch<{
      url: string;
    }>(`/enrolment-requirements/${enrolmentRequirementId}/pay-with-checkout`, {
      method: 'POST',
      body: {},
      query: {
        returnToSuccess: successHref,
        returnToCancel: cancelHref
      }
    });
  }

  public stripeSubscriptionCheckout(
    enrolmentRequirementId: number,
    successHref: string,
    cancelHref: string
  ) {
    return this.fetcher.fetch<{
      url: string;
    }>(
      `/enrolment-requirements/${enrolmentRequirementId}/pay-with-subscription-checkout`,
      {
        method: 'POST',
        body: {},
        query: {
          returnToSuccess: successHref,
          returnToCancel: cancelHref
        }
      }
    );
  }

  public stripeFutureCheckout(
    enrolmentRequirementId: number,
    successHref: string,
    cancelHref: string
  ) {
    return this.fetcher.fetch<{
      url: string;
    }>(
      `/enrolment-requirements/${enrolmentRequirementId}/future-pay-with-checkout`,
      {
        method: 'POST',
        body: {},
        query: {
          returnToSuccess: successHref,
          returnToCancel: cancelHref
        }
      }
    );
  }

  public getMessageConversations(userId: number) {
    return this.fetcher.fetch<{ conversations: MessageConversation[] }>(
      `/users/${userId}/message-conversations`
    );
  }

  public getMessageConversation(conversationId: number) {
    return this.fetcher.fetch<{ conversation: MessageConversation }>(
      `/message-conversations/${conversationId}`
    );
  }

  public createConversation(userId: number, body: MessageConversationBody) {
    return this.fetcher.fetch<{ conversation: MessageConversation }>(
      `/users/${userId}/message-conversations`,
      {
        method: 'POST',
        body
      }
    );
  }

  public createMessage(conversationId: number, body: MessageBody) {
    return this.fetcher.fetch<{ message: Message }>(
      `/message-conversations/${conversationId}/messages`,
      {
        method: 'POST',
        body
      }
    );
  }

  public getMessages(conversationId: number) {
    return this.fetcher.fetch<{ messages: Message[] }>(
      `/message-conversations/${conversationId}/messages`
    );
  }

  //custom fields
  public getChildProgramEntries(childId: number, programId: number) {
    return this.fetcher.fetch<{
      fields: { field: DataField; entry?: DataEntry }[];
    }>(`/children/${childId}/programs/${programId}/fields`);
  }

  public getChildTenantEntries(childId: number, tenantId: number) {
    return this.fetcher.fetch<{
      fields: Fillable[];
    }>(`/children/${childId}/tenants/${tenantId}/fields`);
  }

  public getUserProgramEntries(userId: number, programId: number) {
    return this.fetcher.fetch<{
      fields: { field: DataField; entry?: DataEntry }[];
    }>(`/users/${userId}/programs/${programId}/fields`);
  }

  public getUserTenantEntries(userId: number, tenantId: number) {
    return this.fetcher.fetch<{
      fields: { field: DataField; entry?: DataEntry }[];
    }>(`/users/${userId}/tenants/${tenantId}/fields`);
  }

  public getTenantFields(tenantId: number) {
    return this.fetcher.fetch<{ fields: DataField[] }>(
      `tenants/${tenantId}/fields`
    );
  }

  public getChildrenTenantFields(tenantId: number) {
    return this.fetcher.fetch<{ fields: DataField[] }>(
      `tenants/${tenantId}/children-fields`
    );
  }

  public getUserTenantFields(tenantId: number) {
    return this.fetcher.fetch<{ fields: DataField[] }>(
      `tenants/${tenantId}/user-fields`
    );
  }

  public getProgramFields(programId: number) {
    return this.fetcher.fetch<{ fields: DataField[] }>(
      `programs/${programId}/fields`
    );
  }

  public getPreenrolmentEnrolment(preenrolmentId: number) {
    return this.fetcher.fetch<{ enrolment: EnrolmentRequirement }>(
      `/preenrolments/${preenrolmentId}/enrolment`
    );
  }

  public getPreenrolmentEnrolmentRequirements(preenrolmentId: number) {
    return this.fetcher.fetch<{
      enrolmentRequirements: EnrolmentRequirement[];
    }>(`/preenrolments/${preenrolmentId}/enrolment-requirements`);
  }

  public getProgramUserPreenrolments(programId: number, userId: number) {
    return this.fetcher.fetch<{
      preenrolments: {
        preenrolment_id: number;
        enrolled: boolean;
        participants: {
          type: 'child' | 'user';
          user?: User;
          child?: Child;
        }[];
      }[];
    }>(`/programs/${programId}/users/${userId}/preenrolments`);
  }

  public getPreenrolment(preenrolmentId: number) {
    return this.fetcher.fetch<{ preenrolment: Preenrolment }>(
      `/preenrolments/${preenrolmentId}`
    );
  }

  public removePreenrolment(preenrolmentId: number) {
    return this.fetcher.fetch<{ preenrolment: Preenrolment }>(
      `/preenrolments/${preenrolmentId}`,
      {
        method: 'DELETE',
        body: {}
      }
    );
  }

  public getUserPreenrolment(userId: number) {
    return this.fetcher.fetch<{
      preenrolments: {
        preenrolment_id: number;
        enrolled: boolean;
        program: Program;
        participants: {
          type: 'child' | 'user';
          user?: User;
          child?: Child;
        }[];
      }[];
    }>(`/users/${userId}/preenrolments`);
  }

  public getUserIncompeletePreenrolment(userId: number) {
    return this.fetcher.fetch<{
      preenrolments: {
        preenrolment_id: number;
        enrolled: boolean;
        program: Program;
        participants: {
          type: 'child' | 'user';
          user?: User;
          child?: Child;
        }[];
      }[];
    }>(`/users/${userId}/incomplete-preenrolments`);
  }

  public getUserPurchases(userId: number) {
    return this.fetcher.fetch<{ purchases: Purchase[] }>(
      `/users/${userId}/purchases`
    );
  }

  public getTenantUserPaymentMethods(tenantId: number, userId: number) {
    return this.fetcher.fetch<{
      paymentMethods: {
        id: string;
        type: 'card' | 'acss_debit';
        default: boolean;
        data: {
          bankName?: string;
          brand?: string;
          last4: string;
        };
      }[];
    }>(`/tenants/${tenantId}/users/${userId}/payment-methods`);
  }

  public postTenantUserPaymentMethod(
    tenantId: number,
    userId: number,
    successHref: string,
    cancelHref: string
  ) {
    return this.fetcher.fetch<{ url: string }>(
      `/tenants/${tenantId}/users/${userId}/payment-methods`,
      {
        method: 'POST',
        body: {},
        query: {
          returnToSuccess: successHref,
          returnToCancel: cancelHref
        }
      }
    );
  }

  public postUserField(userId: number, fieldId: number, data: object) {
    return this.fetcher.fetch(`/users/${userId}/fields/${fieldId}`, {
      method: 'POST',
      body: {
        data
      }
    });
  }

  public postChildField(childId: number, fieldId: number, data: object) {
    return this.fetcher.fetch(`/children/${childId}/fields/${fieldId}`, {
      method: 'POST',
      body: {
        data
      }
    });
  }

  public putTenantUserDefaultPaymentMethod(
    tenantId: number,
    userId: number,
    paymentMethodId: string
  ) {
    return this.fetcher.fetch(
      `/tenants/${tenantId}/users/${userId}/default-payment-methods/${paymentMethodId}`,
      {
        method: 'PUT'
      }
    );
  }
  public getUserEnrolments(userId: number) {
    return this.fetcher.fetch<{
      enrolments: { program: Program; children: Child[] }[];
    }>(`/users/${userId}/enrolments`);
  }

  public getTenantProgramSites(tenantId: number) {
    return this.fetcher.fetch<{ programSites: ProgramSite[] }>(
      `/tenants/${tenantId}/program-sites`
    );
  }

  public getTenantProgramTypes(tenantId: number) {
    return this.fetcher.fetch<{ programTypes: ProgramType[] }>(
      `/tenants/${tenantId}/program-types`
    );
  }

  public getUserStripeCharges(userId: number, tenantId: number) {
    return this.fetcher.fetch<{
      stripeCharges: CustomerChargesReturnStripe;
    }>(`/users/${userId}/tenants/${tenantId}/stripe-charges`);
  }

  public deleteUserPaymentMethod(
    tenantId: number,
    userId: string,
    paymentMethodId: string
  ) {
    return this.fetcher.fetch(
      `/tenants/${tenantId}/users/${userId}/payment-methods/${paymentMethodId}`,
      {
        method: 'DELETE',
        body: {}
      }
    );
  }
})();
