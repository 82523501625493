import React, { useState } from 'react';
import { View } from 'react-native';
import { Colors } from '../../constants';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import Button from '../buttons/button';
import { Paragraph1, Paragraph2 } from '../text-family';

export default function WaiverField({
  field,
  entry,
  onChange,
  displayFormError
}: FieldProps) {
  const [agreed, setAgreed] = useState(
    entry && (entry?.data as { agreed: boolean }).agreed === true
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!agreed : true,
    message: 'Please read and agree to the waiver'
  });
  return (
    <View style={{ alignItems: 'center' }}>
      <View
        style={{
          backgroundColor: agreed
            ? 'rgba(0, 128, 0, 0.1)'
            : displayFormError || !error.valid
            ? 'rgba(255, 0, 0, 0.1)'
            : Colors.Background,
          padding: 10,
          borderRadius: 15,
          borderWidth: 1,
          borderColor: agreed
            ? 'green'
            : displayFormError || !error.valid
            ? 'red'
            : Colors.Primary
        }}
      >
        <Paragraph2>
          {(field.data as { waiverText: string }).waiverText}
        </Paragraph2>
      </View>
      {agreed ? (
        <Paragraph1 style={{ alignSelf: 'center', color: 'green' }}>
          Agreed
        </Paragraph1>
      ) : (
        <Button
          style={{
            width: '40%'
          }}
          onPress={() => {
            setAgreed(true);
            if (onChange) {
              onChange({ agreed: true }, fieldIsValid(field, { agreed }).valid);
            }
            setError(fieldIsValid(field, { agreed }));
          }}
        >
          Agree
        </Button>
      )}
    </View>
  );
}
