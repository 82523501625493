import React from 'react';
import { FlatList, View } from 'react-native';
import { Meh } from 'react-native-feather';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import ChildCard from '../../../components/child/child-card';
import ContentContainer from '../../../components/content-container';
import Empty from '../../../components/empty';
import Loading from '../../../components/loading';
import PageTitle from '../../../components/page-title';
import { Colors } from '../../../constants';
import { useChildren } from '../../../hooks';
import useMe from '../../../hooks/use-me';

export default function Children() {
  const me = useMe();
  const { data: children } = useChildren(me?.id);
  const navigator = useNavigator();

  const sortedChildren = children?.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });

  return (
    <View style={{ flex: 1 }}>
      {children ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%'
          }}
        >
          <View
            style={{
              width: '100%',
              flex: 1
            }}
          >
            <FlatList
              ListEmptyComponent={
                <Empty
                  icon={<Meh color={Colors.Primary} width={100} height={100} />}
                  title="No Children"
                  description={`You currently don't have any child added to your profile.`}
                />
              }
              ListHeaderComponent={
                <PageTitle
                  title="Children"
                  button={
                    <Button
                      type="white"
                      onPress={() => {
                        navigator.Go('AddChild');
                      }}
                    >
                      Add Child
                    </Button>
                  }
                />
              }
              stickyHeaderIndices={[0]}
              data={sortedChildren}
              renderItem={({ item, index }) => (
                <ContentContainer>
                  <ChildCard child={item} key={index} />
                </ContentContainer>
              )}
            />
          </View>
        </View>
      ) : (
        <Loading />
      )}
    </View>
  );
}
