import React, { useMemo } from 'react';
import { X } from 'react-feather';
import {
  ScrollView,
  View,
  Dimensions,
  Platform,
  TouchableOpacity,
  Alert
} from 'react-native';
import { Check } from 'react-native-feather';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import ChildCartItem from '../../../components/child/child-cart-item';
import ContentContainer from '../../../components/content-container';
import Divider from '../../../components/divider';
import Empty from '../../../components/empty';
import PageLoading from '../../../components/full-page-loading';
import Label from '../../../components/label';
import PageTitle from '../../../components/page-title';
import ProgramImage from '../../../components/program/program-image';
import { Heading2, Heading4, Heading7 } from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { breakpoint, buttonHorizontalWidth } from '../../../helpers/breakpoint';
import {
  useChildren,
  useUserEnrolments,
  useUserIncompletePreenrolments
} from '../../../hooks';
import useMe from '../../../hooks/use-me';
import api from '../../../api';
import { FetchError } from '../../../helpers/fetcher';


export default function Home() {
  const screenSize = breakpoint();
  const user = useMe();
  const navigator = useNavigator();
  const buttonWidth = buttonHorizontalWidth();
  const { data: enrolments } = useUserEnrolments(user?.id);
  const {
    data: incompletePreenrolments,
    mutate: mutateIncompletePreenrolments
   } = useUserIncompletePreenrolments(
    user?.id
  );
  const { data: children } = useChildren(user?.id);
  const [childrenAdded, pickupPasscodeAdded, enrolledInProgram] =
    useMemo(() => {
      return [!!children?.length, !!user?.pickupPasscode, !!enrolments?.length];
    }, [children, user, enrolments]);
  const accountIsSetup = useMemo(() => {
    return [childrenAdded, pickupPasscodeAdded, enrolledInProgram].every(
      Boolean
    );
  }, [childrenAdded, pickupPasscodeAdded, enrolledInProgram]);
  const loading = [enrolments, incompletePreenrolments, children].some(
    (x) => !x
  );
  function onPressContinue(preEnrolmentId: number) {
    if (Platform.OS === 'web') {
      window.location.href = '/programs/program?programId=' + preEnrolmentId;
    } else {
      navigator.Go('Program', {
        programId: preEnrolmentId
      });
    }
  }

  async function removePreenrolment(preenrolmentId: number) {
    try {
      const removePreenrolmentResult = await api.removePreenrolment(preenrolmentId);
      if (removePreenrolmentResult instanceof FetchError) {
        return Alert.prompt(
          'Unable to locate preenrolment by ID'
        );
      }else{
        const incmpletePreenrolments = await api.getUserIncompeletePreenrolment(user?.id!);
        if (incmpletePreenrolments instanceof FetchError) {
          return Alert.prompt(
            'Unable to fetch preenrolment'
          );
        }else{
          mutateIncompletePreenrolments([...incmpletePreenrolments?.preenrolments])
        }
      }
    }
    finally {
    }
  }


  function onPressViewPrograms() {
    if (Platform.OS === 'web') {
      window.location.href = '/programs';
    } else {
      navigator.Go('Programs');
    }
  }

  function onPressRoute(screen: string, route: string) {
    if (Platform.OS === 'web') {
      return void (window.location.href = route);
    }
    navigator.Go(screen);
  }

  return (
    <View style={{ flex: 1 }}>
      <PageTitle
        title="Welcome"
        // button={
        //   <Button type="white" icon={<Bell color="black" width={14} />}>
        //     Notification
        //   </Button>
        // }
      />

      <ScrollView style={{ flex: 1 }}>
        <ContentContainer style={{ flex: 1 }}>
          {loading ? (
            <PageLoading />
          ) : (
            <View>
              {accountIsSetup ? null : (
                <View>
                  <Heading2>Account Checklist</Heading2>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View>
                      {childrenAdded ? (
                        <Check color="green" />
                      ) : (
                        <X color="red" />
                      )}
                    </View>
                    <View
                      style={{
                        marginLeft: 10,
                        width: '100%'
                      }}
                    >
                      {childrenAdded ? (
                        <Heading4>
                          You have added at least one child to your account.
                        </Heading4>
                      ) : (
                        <View>
                          <Heading4>
                            You have not yet added any children to your account.
                          </Heading4>
                          <TouchableOpacity
                            onPress={() =>
                              onPressRoute('AddChild', '/children/add-child')
                            }
                          >
                            <Heading4
                              style={{
                                color: '#007AFF',
                                textDecorationLine: 'underline'
                              }}
                            >
                              Add a child
                            </Heading4>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View>
                      {pickupPasscodeAdded ? (
                        <Check color="green" />
                      ) : (
                        <X color="red" />
                      )}
                    </View>
                    <View
                      style={{
                        marginLeft: 10,
                        width: '100%',
                        flex: 1
                      }}
                    >
                      {pickupPasscodeAdded ? (
                        <Heading4>
                          You have setup a pickup passcode for your account.
                        </Heading4>
                      ) : (
                        <View>
                          <Heading4>
                            You have not setup a pickup passcode for your
                            account.
                          </Heading4>
                          <TouchableOpacity
                            onPress={() =>
                              onPressRoute('ProfileDetails', '/profile/details')
                            }
                          >
                            <Heading4
                              style={{
                                color: '#007AFF',
                                textDecorationLine: 'underline'
                              }}
                            >
                              Setup Pickup Passcode
                            </Heading4>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View>
                      {enrolledInProgram ? (
                        <Check color="green" />
                      ) : (
                        <X color="red" />
                      )}
                    </View>
                    <View
                      style={{
                        marginLeft: 10,
                        width: '100%',
                        flex: 1
                      }}
                    >
                      {enrolledInProgram ? (
                        <Heading4>
                          You have enrolled at least one child in a program.
                        </Heading4>
                      ) : (
                        <View>
                          <Heading4>
                            You have not enrolled any children in a program.
                          </Heading4>
                          <TouchableOpacity onPress={onPressViewPrograms}>
                            <Heading4
                              style={{
                                color: '#007AFF',
                                textDecorationLine: 'underline'
                              }}
                            >
                              View Programs
                            </Heading4>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              )}
              {incompletePreenrolments?.length ? (
                <View style={{ marginTop: 24 }}>
                  <Heading2>In-Progress Registrations</Heading2>
                  <Heading7>
                    These registration are in-progress. Please complete the
                    registration process to confirm your child's spot.
                  </Heading7>
                  <Card>
                    {incompletePreenrolments?.length ? (
                      <View>
                        {incompletePreenrolments.map((preenrolment, index) => (
                          <View key={index}>
                            <View
                              style={{
                                flexDirection: 'row',
                                width: '100%',
                                alignContent: 'flex-start'
                              }}
                            >
                              <View>
                                <ProgramImage
                                  programId={preenrolment.program.id}
                                  style={{
                                    height: screenSize == Screen.sm ? 50 : 75,
                                    width: screenSize == Screen.sm ? 80 : 120,
                                    borderRadius: 8
                                  }}
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: 'column',
                                  width:
                                    screenSize == Screen.lg
                                      ? ((Dimensions.get('window').width -
                                          160) /
                                          Dimensions.get('window').width) *
                                          100 +
                                        '%'
                                      : ((Dimensions.get('window').width -
                                          100) /
                                          Dimensions.get('window').width) *
                                          100 +
                                        '%'
                                }}
                              >
                                <View
                                  style={{
                                    marginLeft: 10,
                                    width: '100%'
                                  }}
                                >
                                  <Heading4>
                                    {preenrolment.program.name}
                                  </Heading4>

                                  <View
                                    style={{
                                      width: '100%',
                                      marginLeft: 10
                                    }}
                                  >
                                    <Label type="danger">In-Progress</Label>
                                    <View
                                      style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        // justifyContent: 'space-evenly',
                                        flexWrap: 'wrap'
                                      }}
                                    >
                                      {preenrolment.participants.map(
                                        (participant, index) => (
                                          <View
                                            style={{
                                              marginLeft:
                                                index !== 0
                                                  ? screenSize == Screen.lg
                                                    ? 16
                                                    : 8
                                                  : 0
                                            }}
                                            key={index}
                                          >
                                            <ChildCartItem
                                              child={
                                                participant.type == 'user'
                                                  ? participant.user
                                                  : participant.child
                                              }
                                            />
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>

                            <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start'
                            }}
                            >
                              <Button
                                style={{
                                  width: buttonWidth,
                                  margin: '2px',
                                  alignSelf:
                                    screenSize == Screen.lg
                                      ? 'flex-start'
                                      : 'center'
                                }}
                                type="outline"
                                onPress={() => {
                                  onPressContinue(preenrolment.program.id);
                                }}
                              >
                                Continue
                              </Button>

                              <Button
                                style={{
                                  width: buttonWidth,
                                  margin: '2px',
                                  alignSelf:
                                    screenSize == Screen.lg
                                      ? 'flex-start'
                                      : 'center'
                                }}
                                type="outline"
                                onPress={() =>
                                  removePreenrolment(preenrolment.preenrolment_id)
                                }
                              >
                                  Remove
                              </Button>                  
                            </View>                            
                            {index !== incompletePreenrolments.length - 1 ? (
                              <Divider
                                style={{
                                  marginVertical:
                                    screenSize == Screen.sm ? 8 : 16
                                }}
                                orientation="horizontal"
                              />
                            ) : null}
                          </View>
                        ))}
                      </View>
                    ) : (
                      <Card
                        style={{
                          backgroundColor: Colors.OffWhite,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Empty
                            style={{ marginVertical: '3%' }}
                            title="No in-progress registration"
                          />
                          <Button
                            type="primary"
                            style={{
                              width: buttonWidth
                            }}
                            onPress={() => onPressViewPrograms()}
                          >
                            View Programs
                          </Button>
                        </View>
                      </Card>
                    )}
                  </Card>
                </View>
              ) : null}
              {enrolments?.length ? (
                <>
                  <Divider orientation="horizontal" />
                  <View>
                    <Heading2>Registered Programs</Heading2>
                    <Heading7>
                      Last {enrolments?.slice(0, 5).length} programs you
                      recently registered
                    </Heading7>
                    <Card>
                      {enrolments?.length ? (
                        <View>
                          {enrolments.slice(0, 5).map((enrolment, index) => (
                            <View key={index}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  width: '100%',
                                  alignContent: 'flex-start'
                                }}
                              >
                                <View>
                                  <ProgramImage
                                    programId={enrolment.program.id}
                                    style={{
                                      height: screenSize == Screen.sm ? 50 : 75,
                                      width: screenSize == Screen.sm ? 80 : 120,
                                      borderRadius: 8
                                    }}
                                  />
                                </View>
                                <View
                                  style={{
                                    flexDirection: 'column',
                                    width:
                                      screenSize == Screen.lg
                                        ? ((Dimensions.get('window').width -
                                            160) /
                                            Dimensions.get('window').width) *
                                            100 +
                                          '%'
                                        : ((Dimensions.get('window').width -
                                            100) /
                                            Dimensions.get('window').width) *
                                            100 +
                                          '%'
                                  }}
                                >
                                  <View
                                    style={{
                                      marginLeft: 10,
                                      width: '100%'
                                    }}
                                  >
                                    <Heading4>
                                      {enrolment.program.name}
                                    </Heading4>
                                    <View
                                      style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        marginLeft: 10
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: 'row',
                                          width: '100%',
                                          // justifyContent: 'space-evenly',
                                          flexWrap: 'wrap'
                                        }}
                                      >
                                        {enrolment.children.map(
                                          (child, index) => (
                                            <View
                                              style={{
                                                marginLeft:
                                                  index == 0
                                                    ? 0
                                                    : screenSize == Screen.lg
                                                    ? 16
                                                    : 8
                                              }}
                                              key={index}
                                            >
                                              <ChildCartItem child={child} />
                                            </View>
                                          )
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              {index !== enrolments?.slice(0, 5).length - 1 ? (
                                <Divider
                                  style={{
                                    marginVertical:
                                      screenSize == Screen.sm ? 8 : 16
                                  }}
                                  orientation="horizontal"
                                />
                              ) : null}
                            </View>
                          ))}
                        </View>
                      ) : (
                        <Card
                          style={{
                            backgroundColor: Colors.OffWhite,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <View
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Empty
                              style={{ marginVertical: '3%' }}
                              title="No registered programs"
                            />
                            <Button
                              type="primary"
                              style={{
                                width: buttonWidth
                              }}
                              onPress={() => onPressViewPrograms()}
                            >
                              View Programs
                            </Button>
                          </View>
                        </Card>
                      )}
                    </Card>
                  </View>
                </>
              ) : null}
            </View>
          )}
        </ContentContainer>
      </ScrollView>
    </View>
  );
}