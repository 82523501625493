import React, { createContext, useContext, useMemo, useState } from 'react';
import { CompassProps } from '../types';

function mustBeOverridden<T>(): T {
  throw new Error('Must be overridden!');
}

const LocationContext = createContext({
  path: '',
  query: '',
  findComponent: (_: string) => mustBeOverridden<unknown>(),
  updateLocation: () => mustBeOverridden<unknown>()
});

export function useCompass() {
  return useContext(LocationContext);
}

function checkPath(object: any, path: string): boolean {
  if (typeof object === 'string' && object === path) {
    return true;
  }
  return Object.values(object).some((v) =>
    v && typeof v === 'object' ? checkPath(v, path) : v === path
  );
}

function findName(object: any, name: string): any[] {
  let results: any;
  function searchForProp(object: any, name: string) {
    Object.keys(object).forEach((prop) => {
      if (object[prop] && typeof object[prop] === 'object') {
        if (prop === name) {
          results = object[prop];
        } else {
          searchForProp(object[prop], name);
        }
      } else if (object[prop] && typeof object[prop] === 'string') {
        if (prop === name) {
          results = object[prop];
        }
      }
    });
  }
  searchForProp(object, name);
  return results;
}

export default function Compass({
  children,
  linking,
  defaultPath
}: CompassProps) {
  if (
    !checkPath(linking, window.location.pathname) &&
    defaultPath &&
    checkPath(linking, defaultPath)
  ) {
    window.location.href = defaultPath;
  }
  const [{ path: locationPath, query: queryString }, setLocationContext] =
    useState({
      path: window.location.pathname,
      query: window.location.search
    });
  const childrenArray = Array.isArray(children) ? children : [children];
  const child = useMemo(
    () =>
      childrenArray.map((child) => {
        if (!child) {
          return null;
        }
        const AnonymousWrappedChild = () => {
          return child;
        };
        return <AnonymousWrappedChild key={child.props.name} />;
      }),
    [children]
  );

  function findComponent(name: string): boolean {
    if (findName(linking, name)) {
      return checkPath(findName(linking, name), locationPath);
    }
    return false;
  }

  function updateLocation() {
    setLocationContext({
      path: window.location.pathname,
      query: window.location.search
    });
  }

  window.onpopstate = () => {
    setLocationContext({
      path: window.location.pathname,
      query: window.location.search
    });
  };

  return (
    <LocationContext.Provider
      value={{
        path: locationPath,
        query: queryString,
        findComponent,
        updateLocation
      }}
    >
      {child}
    </LocationContext.Provider>
  );
}
