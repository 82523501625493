import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect
} from 'react';
import { Modal as RNModal, View } from 'react-native';
import { X } from 'react-native-feather';
import Button from '../buttons/button';

interface Props extends PropsWithChildren {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onChange?: () => void;
  size: string;
}

export default function Modal(props: Props) {
  useEffect(() => {
    if (props.onChange) {
      props.onChange();
    }
  }, [props.visible]);

  function onClose() {
    props.setVisible(false);
  }

  return (
    <RNModal visible={props.visible} transparent>
      <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.3)' }}>
        <View
          style={{
            backgroundColor: 'white',
            borderColor: '#eee',
            borderRadius: 10,
            borderWidth: 1,
            height: props.size,
            margin: 'auto',
            padding: 30,
            width: props.size
          }}
        >
          <Button
            style={{ alignSelf: 'flex-end', width: '25%' }}
            onPress={onClose}
            icon={<X color={'black'} />}
          />
          {props.children}
        </View>
      </View>
    </RNModal>
  );
}
