import React, { ReactNode, useEffect } from 'react';
import Smartlook from 'smartlook-client';
import { useMe } from '../hooks';

interface Props {
  children: ReactNode;
}

export default function SmartlookProvider({ children }: Props) {
  const me = useMe().data;
  useEffect(() => {
    if (me) {
      Smartlook.identify(me.id, {
        email: me.username,
        name: me.firstName + ' ' + me.lastName
      });
    }
  }, [me]);
  Smartlook.init('575a0fee37792b007e6f8465eb7ffd640e5a037e');
  Smartlook.record({
    emails: true,
    forms: true,
    numbers: true
  });
  return <>{children}</>;
}
