import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { NativeBaseProvider } from 'native-base';
import React, { useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppContext from './src/context/app-context';
import SmartlookProvider from './src/providers/smartlook.provider';
import Router from './src/router';

SplashScreen.preventAutoHideAsync();
Object.assign(global, { NODE_ENV: {}, process: {} });

export default function App() {
  const [fontsLoaded] = useFonts({
    'Poppins-Regular': require('./src/assets/fonts/Poppins-Regular.ttf'),
    'Poppins-SemiBold': require('./src/assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-ExtraBold': require('./src/assets/fonts/Poppins-ExtraBold.ttf')
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ flex: 1 }}>
      <SmartlookProvider>
        <NativeBaseProvider>
          <AppContext>
            <SafeAreaProvider>
              <Toaster />
              <Router />
            </SafeAreaProvider>
          </AppContext>
        </NativeBaseProvider>
      </SmartlookProvider>
    </View>
  );
}
