import { Platform } from 'react-native';
import { Config } from './config.common';

const config: Config = {
  authUrlNative:
    Platform.OS === 'ios' || Platform.OS === 'android'
      ? 'https://auth.enrolr.com/authorize?redirect_uri=https://auth.enrolr.com/_implicit'
      : 'https://auth.enrolr.com',
  authUrlWeb:
    'https://auth.enrolr.com/authorize?redirect_uri=https://app.enrolr.com/cb',
  backendUrl: 'https://cachedcontent.enrolr.com',
  environment: 'prod'
};
export default config;
