import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

const { default: modalInferred } = require('./modal');

interface Props extends PropsWithChildren {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onChange?: () => void;
  size: string;
}

export const Modal: (props: Props) => JSX.Element = modalInferred;
